import UpsellCheckbox from '../shared/UpsellCheckbox';
import UpsellCheckboxCondensed from './UpsellCheckboxCondensed';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellGroups.propTypes = {
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default function UpsellGroups({ item, prefix }) {
  const accessoryUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 2)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const replaceUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 1)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const preSelectedGroup = accessoryUpsells.slice(0, 1).map((upsell) => {
    return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
  });

  const replaceGroup = replaceUpsells.map((upsell) => {
    return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
  });

  const optionalGroup = accessoryUpsells.slice(1, accessoryUpsells.length - 1).map((upsell) => {
    return <UpsellCheckboxCondensed item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
  });

  const classificationGroup = accessoryUpsells.slice(-1).map((upsell) => {
    return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
  });

  const hasUpgrades = accessoryUpsells.length > 0;

  return (
    <>
      {hasUpgrades && (
        <div className="upsell-group upgrades-group">
          {preSelectedGroup}
          {replaceGroup}

          {accessoryUpsells.length > 3 && optionalGroup.length && (
            <div className="optional-items">
              <h3 className="fs-4 fw-400 mt-4 mb-2">Compliance and Optional Items</h3>
              <p>
                Listed below are a few additional items that we offer for new companies. To add any of these items to your
                order, simply check the box next to the item(s) you need.
              </p>
              {optionalGroup}
            </div>
          )}

          {accessoryUpsells.length > 3 && classificationGroup.length && (
            <div className="optional-items">
              <h3 className="fs-4 fw-400 mt-4 mb-2">Entity Classification</h3>
              {classificationGroup}
            </div>
          )}
        </div>
      )}
    </>
  );
}
