import cookies from '../utils/cookies';
import Cart1 from './cart/v1';
import Cart2 from './cart/v2';
import FisCart from './cart/fis';

const CartWrapper = () => {
  const useCartV2 = cookies.get('testVariant') === 'useCartV2'; // retaining for legacy cart swithing

  // new way to switch to specific cart based on cookie
  const useCart = cookies.get('useCart') ? cookies.get('useCart') : useCartV2 ? 'v2' : null;

  switch (useCart) {
    case 'fis': {
      return <FisCart />;
    }
    case 'v2': {
      return <Cart2 />;
    }
    case 'v1': {
      return <Cart1 />;
    }
    default: {
      return <Cart2 />;
    }
  }
};

// the index page is the cart...
export default CartWrapper;
