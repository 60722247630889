import { useContext, useMemo } from 'react';
import UpsellCheckbox from '../shared/UpsellCheckbox';
import UpsellDigital from './UpsellDigital';
import UpsellShipping from './UpsellShipping';
import { CartContext } from '../../store/CartContext';
import hideUpsellInCart from '../../utils/hideUpsellInCart';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellGroups.propTypes = {
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default function UpsellGroups({ item, prefix }) {
  const [cartState] = useContext(CartContext);

  const cartProductIds = useMemo(() => cartState.cartProducts.map((item) => item.productId), [cartState.cartProducts]);
  const isSingle = cartProductIds.length === 1;

  const replaceUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 1)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex)
    .map((upsell) => {
      return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
    });

  const accessoryUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 2)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex)
    .map((upsell) => {
      return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
    });

  const digitalUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 3)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex)
    .map((upsell) => {
      return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
    });

  const shippingUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 4)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex)
    .map((upsell) => {
      return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
    });

  const relatedUpsells = item.upSellProducts
    .filter(
      (upsell) =>
        upsell.upsellSectionId === 5 &&
        (!hideUpsellInCart(upsell.productId, item.pricingUsed) || upsell.isSelected) &&
        ((!isSingle && upsell.isSelected) || (isSingle && !cartProductIds.includes(upsell.productId)))
    )
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex)
    .map((upsell) => {
      return <UpsellCheckbox item={item} upsell={upsell} prefix={prefix} key={upsell.productId} />;
    });

  const hasUpgrades = replaceUpsells.length > 0 || accessoryUpsells.length > 0;
  const hasDelivery = digitalUpsells.length > 0 || shippingUpsells.length > 0;
  const hasDigitalDelivery = digitalUpsells.length > 0;
  const hasShippingDelivery = shippingUpsells.length > 0;
  const hasShippingDeliverySelected = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 4)
    .some((upsell) => upsell.isSelected === true);
  const hasRelated = relatedUpsells.length > 0;

  return (
    <>
      {hasUpgrades && (
        <div className="upsell-group upgrades-group">
          <h3 className="fs-5 fw-400 mt-2 mt-lg-0 mb-2">Course Upgrades & Add-ons:</h3>
          {replaceUpsells}
          {accessoryUpsells}
        </div>
      )}

      {hasDelivery && (
        <div className="upsell-group delivery-group">
          <h3 className="fs-5 fw-400 mt-4 mb-2">Delivery Upgrades:</h3>
          {hasDigitalDelivery && (
            <div className={`upsell-item rounded is-selected ${cartState.isLoading ? 'is-loading' : ''}`}>
              <UpsellDigital item={item} prefix={prefix} />
            </div>
          )}
          {hasShippingDelivery && (
            <div
              className={`upsell-item rounded ${hasShippingDeliverySelected ? 'is-selected' : ''} ${
                cartState.isLoading ? 'is-loading' : ''
              }`}
            >
              <UpsellShipping item={item} prefix={prefix} />
            </div>
          )}
        </div>
      )}

      {/* only show related upsells when it is */}
      {hasRelated && !isSingle && (
        <div className="upsell-group related-group">
          <h3 className="fs-5 fw-400 mt-4 mb-2">Others Also Purchased:</h3>
          {relatedUpsells}
        </div>
      )}
    </>
  );
}
