import { useState, useContext, useEffect, useRef } from 'react';
import { formatCurrency as currency } from '../../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../../utils/amountHelper';
import { ecomAddProduct, ecomRemoveProduct } from '../../utils/ecomHelper';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellCheckboxCondensed.propTypes = {
  upsell: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default function UpsellCheckboxCondensed({ item, upsell, prefix = '' }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const isUpsellTypeReplace = upsell.upsellType === 'Replace';

  const upsellDisplayPrice = getDisplayPrice(upsell, true);
  const origPrice = getOrigPrice(upsell, true);

  const origPriceDisplay = isUpsellTypeReplace
    ? origPrice -
      (!upsell.hiddenFee
        ? item.unitPriceWithDiscountAndFeeIfHidden + upsell.unitThirdPartyFee
        : item.unitPriceWithDiscountAndFeeIfHidden)
    : origPrice;

  const priceDisplay = isUpsellTypeReplace
    ? upsellDisplayPrice -
      (!upsell.hiddenFee
        ? item.unitPriceWithDiscountAndFeeIfHidden + upsell.unitThirdPartyFee
        : item.unitPriceWithDiscountAndFeeIfHidden)
    : upsellDisplayPrice;

  const [isChecked, setIschecked] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setIschecked(upsell.isSelected);
  }, [upsell.isSelected]);

  const storeCart = (cartData) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        ...cartData,
        isLoading: false,
        isUpdatingCart: false,
      },
    });

    // trigger to show the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showNotification: true,
      },
    });
  };

  const storeCartProducts = (updatedCartProducts) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        cartProducts: updatedCartProducts,
        isLoading: true,
        isUpdatingCart: true,
      },
    });
  };

  const upsellDebounce = useRef();

  const handleChange = () => {
    setIsUpdating(true);

    setIschecked(!isChecked);

    // trigger to hide the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showNotification: false,
      },
    });

    const updatedCartProducts = cartState?.cartProducts?.map((product) => {
      if (product.cart_ProductId === item.cart_ProductId) {
        const upsells = product.upSellProducts;
        const updatedUpsells = upsells.map((productUpsell) => {
          if (productUpsell.cart_ProductId === upsell.cart_ProductId) {
            if (productUpsell.upsellType === 'Replace') {
              productUpsell.subTotal = productUpsell.subTotal - item.unitPriceWithDiscountAndFeeIfHidden;
              productUpsell.unitPriceWithDiscountAndFee =
                productUpsell.unitPriceWithDiscountAndFee - item.unitPriceWithDiscountAndFeeIfHidden;
              productUpsell.unitPriceWithDiscountAndFeeIfHidden =
                productUpsell.unitPriceWithDiscountAndFeeIfHidden - item.unitPriceWithDiscountAndFeeIfHidden;
            }
            // console.log('Update Upsell', {
            //   ...productUpsell,
            //   isSelected: !isChecked,
            // });
            return {
              ...productUpsell,
              // isSelected: !isChecked,
              // below is a hotfix for a cart API issue, need to remove when cart API is fixed
              isSelected: productUpsell.upsellType !== 'Replace' ? !isChecked : productUpsell.isSelected,
            };
          }
          return productUpsell;
        });
        return {
          ...product,
          upSellProducts: updatedUpsells,
        };
      }
      return product;
    });

    storeCartProducts(updatedCartProducts);

    // debouncing API call in case the user repeated clicks the upsell option
    clearTimeout(upsellDebounce.current);
    upsellDebounce.current = setTimeout(() => {
      const upsellData = {
        cartProductId: upsell.cart_ProductId, // upsell cart_ProductId
        cartId: item.cartId, // cartId
        productId: upsell.productId,
        quantity: item.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
        isSelected: !isChecked, // true/false, add or remove upsell
      };

      API.updateUpsell(upsellData)
        .then((res) => {
          // console.log('cart', res);
          const cart = res.data;
          setIsUpdating(false);
          storeCart(cart);

          // push event and data to dataLayer
          if (upsellData.isSelected) {
            ecomAddProduct(upsell);
          } else {
            ecomRemoveProduct(upsell);
          }
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Upsell was not updated!');
          }
        });
    }, 300);
  };

  const showUpsellDescription = () => {
    // trigger to show the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showUpsellDescription: {
          name: upsell.name,
          description: upsell.description,
        },
      },
    });
  };

  return (
    <div
      className={`upsell-item condensed-item rounded ${isChecked ? 'is-selected' : ''} ${
        cartState.isLoading ? 'is-loading' : ''
      }`}
      data-upsell-type={upsell.upsellType}
      data-product-id={upsell.productId}
    >
      <div className="form-check">
        {isUpdating ? (
          <span className="spinner-border spinner-border-sm upsell-check" role="status" aria-hidden="true"></span>
        ) : (
          <input
            className="form-check-input"
            type="checkbox"
            value={upsell.productId}
            id={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}
            checked={isChecked}
            onChange={handleChange}
            data-cart-product-id={upsell.cart_ProductId}
            data-upsell-type={upsell.upsellType}
          />
        )}

        {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
        <div className="d-flex justify-content-between">
          <label className="form-check-label d-block" htmlFor={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}>
            <span className="fw-600 d-block d-sm-flex">
              <span className="upsell-name fw-400 d-inline-block">
                {upsell.name}{' '}
                <span className="d-inline-block">
                  <span className="upsell-price d-sm-none text-end fw-400">
                    <span className="d-sm-none">(</span>
                    {upsell.upsellType === 'Replace' && upsell.isSelected ? (
                      'Included'
                    ) : (
                      <>
                        {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                          <span className="orig-price me-2">+{currency(origPriceDisplay)}</span>
                        )}
                        +{currency(priceDisplay)}
                      </>
                    )}
                    <span className="d-sm-none">)</span>
                  </span>{' '}
                  <button
                    className="btn-toggle fw-400 small text-reset lh-1 p-0 me-1"
                    type="button"
                    onClick={showUpsellDescription}
                  >
                    <span className="info-icon align-middle">?</span>
                  </button>
                </span>
              </span>
            </span>
          </label>
          <span className="upsell-price d-none d-sm-inline-block text-end fw-400">
            <span className="d-sm-none">(</span>
            {upsell.upsellType === 'Replace' && upsell.isSelected ? (
              'Included'
            ) : (
              <>
                {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                  <span className="orig-price me-2">+{currency(origPriceDisplay)}</span>
                )}
                +{currency(priceDisplay)}
              </>
            )}
            <span className="d-sm-none">)</span>
          </span>
        </div>
      </div>
    </div>
  );
}
