import { useState, useEffect, useContext, useRef } from 'react';
import { Modal } from 'bootstrap';
import { CartContext } from '../../store/CartContext';

export default function UpsellDescriptionModal() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const upsellDescriptionModalEl = useRef();

  const [upsellDescriptionModal, setUpsellDescriptionModal] = useState(null);
  const [upsellItem, setUpsellItem] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    setUpsellDescriptionModal(new Modal(upsellDescriptionModalEl.current));

    upsellDescriptionModalEl.current.addEventListener('hide.bs.modal', () => {
      // trigger to hide the toast notification
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          showUpsellDescription: null,
        },
      });
    });
  }, [cartDispatch]);

  useEffect(() => {
    if (!cartState.userExperience.showUpsellDescription && upsellDescriptionModal) {
      upsellDescriptionModal.hide();
    }
    if (cartState.userExperience.showUpsellDescription && upsellDescriptionModal) {
      upsellDescriptionModal.show();
    }
  }, [cartState.userExperience.showUpsellDescription, upsellDescriptionModal]);

  useEffect(() => {
    if (cartState.userExperience.showUpsellDescription) {
      setUpsellItem({
        name: cartState.userExperience.showUpsellDescription.name,
        description: cartState.userExperience.showUpsellDescription.description,
      });
    }
  }, [cartState.cartProducts, cartState.userExperience.showUpsellDescription]);

  return (
    <div
      className="modal fade text-start lh-16"
      ref={upsellDescriptionModalEl}
      id="upsell-description-modal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="upsell-description-modal modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body p-3 p-md-4">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3 className="modal-title fw-600 h5 mb-2">{upsellItem.name}</h3>
            <p className="line-break fw-400 mb-0">{upsellItem.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
