// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'ny5HourPreLicensing', // this used as the field name as well as the unique identifier
    productIds: [
      'f0f40088-fb65-4b9b-94e2-bdb4631cc1e2', // New York 5-Hour Online Pre-Licensing Course
    ],
    errorMsg: `The Student must accept the <strong>New York 5-Hour Online Pre-Licensing Course</strong> enrollment contract by typing "<strong>I agree</strong>" in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'iAgree', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      The Student accepts the New York Online Pre-Licensing course terms as outlined in the{' '}
      <a
        href="https://cdn.certus.com/global/pdf/ny-5-hour-enrollment-contract.pdf"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        New York Student Enrollment Contract
      </a>{' '}
      by typing &quot;I Agree&quot; in the field below.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
