import { useEffect, useContext, useCallback, useMemo } from 'react';
import { CartContext } from '../../store/CartContext';
import FieldItem from './FieldItem';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
FieldGroup.propTypes = {
  cartProductIds: PropTypes.array.isRequired,
  fieldType: PropTypes.string.isRequired,
  attestItems: PropTypes.array.isRequired,
  attestForm: PropTypes.object.isRequired,
  setAttestForm: PropTypes.func.isRequired,
};

export default function FieldGroup({ cartProductIds, fieldType, attestItems, attestForm, setAttestForm }) {
  const [cartState] = useContext(CartContext);

  const showItem = useCallback(
    (field) => {
      // check if one of the field productIds is in the cart
      const shouldValidate = field.productIds.some((productId) => cartProductIds.includes(productId));
      const isBulkOrder = field.productIds.some(
        (productId) => cartState.cartProducts.find((item) => item.productId === productId)?.quantity > 1
      );
      return field.onlyForBulk ? shouldValidate && isBulkOrder : shouldValidate;
    },
    [cartProductIds, cartState.cartProducts]
  );

  // this is still not being ordered according to the cartProductIds!!!
  const orderedAttestItems = useMemo(
    () =>
      attestItems.sort(
        (itemA, itemB) =>
          cartProductIds.findIndex((id) => itemA.productIds.includes(id)) -
          cartProductIds.findIndex((id) => itemB.productIds.includes(id))
      ),
    [attestItems, cartProductIds]
  );

  const filteredAttestItems = useMemo(
    () =>
      cartProductIds
        .map((id) => orderedAttestItems.find((item) => item.productIds.includes(id)))
        .filter(Boolean)
        .map((field) => {
          const displayItem = showItem(field);

          if (displayItem) {
            return field;
          }
        })
        .filter(Boolean)
        .filter((item, pos, arr) => arr.indexOf(item) === pos), // remove duplicate attestation
    [cartProductIds, orderedAttestItems, showItem]
  );

  const displayGroup = filteredAttestItems.length > 0;

  // this adds this grouped field's info into the parent AttestationForm component's state
  useEffect(() => {
    const sessionAttestForm = sessionStorage.getItem('attestForm') && JSON.parse(sessionStorage.getItem('attestForm'));

    setAttestForm((state) => {
      const formFields = { ...state.fields };

      // console.log(formFields);

      if (!displayGroup) {
        delete formFields[fieldType];
        delete formFields.parentSignature; // manually removing the parentSignature
      } else {
        formFields[fieldType] = {
          value: sessionAttestForm?.fields[fieldType] ? sessionAttestForm?.fields[fieldType].value : '',
          isValid: sessionAttestForm?.fields[fieldType]
            ? sessionAttestForm?.fields[fieldType].isValid
            : !displayGroup
            ? true
            : false,
        };
      }

      return {
        ...state,
        fields: {
          ...formFields,

          // manually adding parentSignature if multiSignature attestation
          ...(fieldType === 'multiSignature' &&
            displayGroup && {
              parentSignature: {
                value: sessionAttestForm?.fields?.parentSignature ? sessionAttestForm?.fields.parentSignature.value : '',
                isValid: true,
              },
            }),
        },
      };
    });
  }, [displayGroup, fieldType, setAttestForm]);

  // handle error collection then setting the attestationForm state
  useEffect(() => {
    const isGroupValid = attestForm.fields[fieldType]?.isValid || false;

    setAttestForm((state) => {
      const errorCollection = { ...state.errors };

      for (const item of orderedAttestItems) {
        const displayItem = showItem(item);

        if (!displayItem || isGroupValid) {
          // removing the current field's error if attestation is not displayed
          // or if the grouped field have is valid
          delete errorCollection[item.name];
        } else {
          errorCollection[item.name] = item.errorMsg; // current field's errorMsg
        }
      }

      return {
        ...state,
        errors: errorCollection,
      };
    });
  }, [attestForm.fields, fieldType, orderedAttestItems, setAttestForm, showItem]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;

    let isValid = false;

    const setIsValid = (condition) => {
      if (condition) {
        return true;
      }
      return false;
    };

    // adding/removing error message for this field
    if (fieldType === 'signature' || fieldType === 'multiSignature') {
      isValid = setIsValid(theValue.toLowerCase() && theValue.toLowerCase().trim().length >= 2);
    } else {
      isValid = setIsValid(theValue.toLowerCase().trim() === 'i agree');
    }

    setAttestForm((state) => ({
      ...state,
      fields: {
        ...state.fields,
        [name]: {
          value: theValue,
          isValid: isValid,
        },
      },
    }));
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setAttestForm((state) => ({
      ...state,
      touched: {
        ...state.touched,
        [name]: true,
      },
    }));
  };

  const handleModalClose = (fieldName) => {
    setAttestForm((state) => {
      // removing error message for this field
      delete state.errors[fieldName];

      return {
        ...state,
        fields: {
          ...state.fields,
          [fieldName]: {
            value: 'I agree',
            isValid: true,
          },
        },
        errors: state.errors,
      };
    });
  };

  const attestationList = filteredAttestItems?.map((item) => (
    <FieldItem
      key={item.name}
      attestItem={item}
      isSingle={filteredAttestItems.length === 1}
      handleModalClose={handleModalClose}
      fieldType={fieldType}
    />
  ));

  if (displayGroup) {
    return (
      <>
        <div
          className={`card attestation-group notranslate ${
            (attestForm.touched[fieldType] || cartState.validation.formTouched) && !attestForm.fields[fieldType]?.isValid
              ? 'bg-warning bg-opacity-10 border-danger-subtle'
              : 'bg-warning bg-opacity-10 border-warning-subtle'
          }  my-3`}
        >
          <div className="card-body">
            <h3 className="h5 fw-600">
              {fieldType === 'signature' || fieldType === 'multiSignature' ? 'Electronic Signature' : 'Attestation Form'}
            </h3>
            {filteredAttestItems.length > 1 ? (
              <>
                <ul className="ps-4">{attestationList}</ul>
              </>
            ) : (
              <div className="mb-3">{attestationList}</div>
            )}
            <div className="row align-items-top">
              <div className="col-sm-6">
                {fieldType === 'multiSignature' ? (
                  <label className="mb-1 ps-1" htmlFor={fieldType}>
                    Student Name
                  </label>
                ) : null}
                <input
                  className={`qty-input form-control ${
                    (attestForm.touched[fieldType] || cartState.validation.formTouched) &&
                    !attestForm.fields[fieldType]?.isValid &&
                    'is-invalid'
                  }`}
                  id={fieldType}
                  name={fieldType}
                  type="text"
                  placeholder={
                    fieldType === 'signature'
                      ? 'Your full legal name'
                      : fieldType === 'multiSignature'
                      ? 'Student full legal name'
                      : 'Type "I agree"'
                  }
                  value={attestForm.fields[fieldType]?.value || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {(attestForm.touched[fieldType] || cartState.validation.formTouched) &&
                  !attestForm.fields[fieldType]?.isValid && (
                    <div className="fw-500 text-danger small ps-1 mt-1 mt-sm-0">
                      Please type{' '}
                      <strong>
                        {fieldType === 'signature'
                          ? 'your full legal name'
                          : fieldType === 'multiSignature'
                          ? 'student full legal name'
                          : '"I agree"'}
                      </strong>{' '}
                      in the box
                    </div>
                  )}
              </div>
              {fieldType === 'multiSignature' ? (
                <div className="col-sm-6 mt-2 mt-sm-0">
                  <label className="mb-1 ps-1" htmlFor="parentSignature">
                    Parent/Guardian Name
                  </label>
                  <input
                    className={`qty-input form-control col-6`}
                    id="parentSignature"
                    name="parentSignature"
                    type="text"
                    placeholder="Parent/guardian full legal name"
                    value={attestForm.fields.parentSignature?.value || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}
