import { useState, useContext, useEffect, useRef } from 'react';
import { formatCurrency as currency } from '../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../utils/amountHelper';
import { ecomAddProduct, ecomRemoveProduct } from '../utils/ecomHelper';
import API from '../services/apiService';
import { CartContext } from '../store/CartContext';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellCheckbox.propTypes = {
  upsell: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default function UpsellCheckbox({ item, upsell, prefix = '' }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const isUpsellTypeReplace = upsell.upsellType === 'Replace';

  const upsellDisplayPrice = getDisplayPrice(upsell, true);
  const origPrice = getOrigPrice(upsell, true);

  const origPriceDisplay = isUpsellTypeReplace
    ? origPrice -
      (!upsell.hiddenFee
        ? item.unitPriceWithDiscountAndFeeIfHidden + upsell.unitThirdPartyFee
        : item.unitPriceWithDiscountAndFeeIfHidden)
    : origPrice;

  const priceDisplay = isUpsellTypeReplace
    ? upsellDisplayPrice -
      (!upsell.hiddenFee
        ? item.unitPriceWithDiscountAndFeeIfHidden + upsell.unitThirdPartyFee
        : item.unitPriceWithDiscountAndFeeIfHidden)
    : upsellDisplayPrice;

  const [isChecked, setIschecked] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setIschecked(upsell.isSelected);
  }, [upsell.isSelected]);

  const storeCart = (cartData) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        ...cartData,
        isLoading: false,
      },
    });
  };

  const storeCartProducts = (updatedCartProducts) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        cartProducts: updatedCartProducts,
        isLoading: true,
      },
    });
  };

  const upsellDebounce = useRef();

  const handleChange = () => {
    setIsUpdating(true);

    setIschecked(!isChecked);

    const updatedCartProducts = cartState?.cartProducts?.map((product) => {
      if (product.cart_ProductId === item.cart_ProductId) {
        const upsells = product.upSellProducts;
        const updatedUpsells = upsells.map((productUpsell) => {
          if (productUpsell.cart_ProductId === upsell.cart_ProductId) {
            if (productUpsell.upsellType === 'Replace') {
              productUpsell.subTotal = productUpsell.subTotal - item.unitPriceWithDiscountAndFeeIfHidden;
              productUpsell.unitPriceWithDiscountAndFee =
                productUpsell.unitPriceWithDiscountAndFee - item.unitPriceWithDiscountAndFeeIfHidden;
              productUpsell.unitPriceWithDiscountAndFeeIfHidden =
                productUpsell.unitPriceWithDiscountAndFeeIfHidden - item.unitPriceWithDiscountAndFeeIfHidden;
            }
            // console.log('Update Upsell', {
            //   ...productUpsell,
            //   isSelected: !isChecked,
            // });
            return {
              ...productUpsell,
              // isSelected: !isChecked,
              // below is a hotfix for a cart API issue, need to remove when cart API is fixed
              isSelected: productUpsell.upsellType !== 'Replace' ? !isChecked : productUpsell.isSelected,
            };
          }
          return productUpsell;
        });
        return {
          ...product,
          upSellProducts: updatedUpsells,
        };
      }
      return product;
    });

    storeCartProducts(updatedCartProducts);

    // debouncing API call in case the user repeated clicks the upsell option
    clearTimeout(upsellDebounce.current);
    upsellDebounce.current = setTimeout(() => {
      const upsellData = {
        cartProductId: upsell.cart_ProductId, // upsell cart_ProductId
        cartId: item.cartId, // cartId
        productId: upsell.productId,
        quantity: item.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
        isSelected: !isChecked, // true/false, add or remove upsell
      };

      API.updateUpsell(upsellData)
        .then((res) => {
          // console.log('cart', res);
          const cart = res.data;
          setIsUpdating(false);
          storeCart(cart);

          // push event and data to dataLayer
          if (upsellData.isSelected) {
            ecomAddProduct(upsell);
          } else {
            ecomRemoveProduct(upsell);
          }
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Upsell was not updated!');
          }
        });
    }, 300);
  };

  return (
    <div
      className={`upsell-item rounded ${isChecked ? 'is-selected' : ''} ${cartState.isLoading ? 'is-loading' : ''}`}
      data-upsell-type={upsell.upsellType}
      data-product-id={upsell.productId}
    >
      <div className="form-check">
        {isUpdating ? (
          <span className="spinner-border spinner-border-sm upsell-check" role="status" aria-hidden="true"></span>
        ) : (
          <input
            className="form-check-input"
            type="checkbox"
            value={upsell.productId}
            id={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}
            checked={isChecked}
            onChange={handleChange}
            data-cart-product-id={upsell.cart_ProductId}
            data-upsell-type={upsell.upsellType}
          />
        )}

        {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
        <label className="form-check-label" htmlFor={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}>
          <span className="fw-600 d-block mb-1">
            <span className="upsell-name">{upsell.name}</span>{' '}
            <span className="upsell-price d-inline-block">
              (
              {upsell.upsellType === 'Replace' && upsell.isSelected ? (
                'Included'
              ) : (
                <>
                  {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                    <span className="orig-price me-2">+{currency(origPriceDisplay)}</span>
                  )}
                  +{currency(priceDisplay)}
                </>
              )}
              )
            </span>
          </span>
          <span className="upsell-description d-block lh-15">{upsell.description}</span>
        </label>
      </div>
    </div>
  );
}
