import { useState, useEffect, useContext, useRef } from 'react';
import { Modal } from 'bootstrap';
import { CartContext } from '../../store/CartContext';
import { formatCurrency as currency } from '../../utils/formatCurrency';
import parse from 'html-react-parser';

export default function BulkRatesModal() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const bulkModalEl = useRef();

  const [bulkRatesModal, setBulkRatesModal] = useState(null);
  const [product, setProduct] = useState({
    name: '',
    bulkRates: '',
  });

  useEffect(() => {
    setBulkRatesModal(new Modal(bulkModalEl.current));

    bulkModalEl.current.addEventListener('hide.bs.modal', () => {
      // trigger to hide the toast notification
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          showBulkRates: null,
        },
      });
    });
  }, [cartDispatch]);

  useEffect(() => {
    if (!cartState.userExperience.showBulkRates && bulkRatesModal) {
      bulkRatesModal.hide();
    }
    if (cartState.userExperience.showBulkRates && bulkRatesModal) {
      bulkRatesModal.show();
    }
  }, [cartState.userExperience.showBulkRates, bulkRatesModal]);

  useEffect(() => {
    if (cartState.userExperience.showBulkRates) {
      const bulkRateInfo = () => {
        // WORKAROUND: deduping the bulk tiers array because the cart API is returning duplicate bulk tiers
        // const cleanBulkTiers = cartProductBulkTiers.filter(
        //   (tier, index, arr) => arr.findIndex((tier2) => tier2.minQuantity === tier.minQuantity) === index
        // );

        const bulkTiers = cartState.userExperience.showBulkRates.bulkRates.map((tier, index) => {
          return {
            ...tier,
            maxQuantity: cartState.userExperience.showBulkRates.bulkRates[index + 1]
              ? cartState.userExperience.showBulkRates.bulkRates[index + 1].minQuantity - 1
              : null,
          };
        });

        let bulkRates = '';

        for (let i = 0; i < bulkTiers.length; i++) {
          const bulkPrice = currency(bulkTiers[i].discountTierUnitPrice);
          const minQuantity = bulkTiers[i].minQuantity;
          const maxQuantity = bulkTiers[i].maxQuantity;

          bulkRates += `Buy <span class="fw-700">${minQuantity}${
            maxQuantity ? `-${maxQuantity}` : ' or more'
          }</span> and get a unit price of <span class="fw-700">${bulkPrice}</span>${maxQuantity ? '<br/>' : ''}`;
        }

        return bulkRates;
      };
      setProduct({
        name: cartState.userExperience.showBulkRates.name,
        bulkRates: bulkRateInfo(),
      });
    }
  }, [cartState.cartProducts, cartState.userExperience.showBulkRates]);

  return (
    <div className="modal fade text-start lh-16" ref={bulkModalEl} id="bulk-rates-modal" tabIndex="-1" aria-hidden="true">
      <div className="bulk-modal modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-3 p-md-4">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3 className="modal-title fw-600 h5 mb-3">Bulk Rates for {product.name}</h3>
            <p className="line-break fw-500 mb-0">{parse(product.bulkRates)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
