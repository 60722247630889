import { useEffect, useContext, useRef } from 'react';
import { CartContext } from '../store/CartContext';
import { Modal } from 'bootstrap';
import UpsellGroups from './UpsellGroups';
import useBrand from '../hooks/useBrand';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellModal.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function UpsellModal({ item }) {
  const [cartState, cartDispatch] = useContext(CartContext);
  const { domain } = useBrand();

  const VITE_APP_MODE = import.meta.env.MODE;
  const NODE_ENV = process.env.NODE_ENV.toLowerCase();
  const isDevMode = import.meta.env.DEV;

  let shopUrl = `https://dlshop.${domain}`;

  if (VITE_APP_MODE === 'localDev' && isDevMode) {
    shopUrl = `https://shop.${domain}`;
  }
  if (NODE_ENV === 'staging') {
    shopUrl = `https://mlshop.${domain}`;
  }
  if (NODE_ENV === 'production') {
    shopUrl = `https://shop.${domain}`;
  }

  const checkUrl = `${shopUrl}/checkout/?cartId=${cartState.cartId}`;

  const upsellModalEl = useRef();
  const checkoutBtn = useRef(null);

  useEffect(() => {
    if (cartState.userExperience.showUpsellModal) {
      // hack to manually remove the existing modal backdrop during rerender
      document.querySelector('.modal-backdrop') && document.querySelector('.modal-backdrop').remove();
      document.querySelector('body').style.overflow = null;
      document.querySelector('body').style.paddingRight = null;

      const modal = new Modal(upsellModalEl.current, {
        backdrop: 'static',
        keyboard: false,
      });

      modal.show();

      checkoutBtn.current = document.querySelector('button.checkout-btn');
      upsellModalEl.current.addEventListener('hidden.bs.modal', () => {
        checkoutBtn.current.focus();
      });
    }
  }, [cartState.userExperience.showUpsellModal]);

  const handleModalCheckout = () => {
    sessionStorage.setItem('viewCheckout', true);
    window.location.href = checkUrl;
  };

  const handleModalClose = () => {
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        viewedUpsells: true,
        showUpsellModal: false,
      },
    });
  };

  return (
    <>
      <div className="modal modal-lg fade text-start" ref={upsellModalEl} id="upsell-modal" tabIndex="-1" aria-hidden="true">
        <div className="upsell-modal modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
            ></button>
            <div className="modal-body p-3 pt-3 px-3 pb-2 p-4 pt-4 pb-3" tabIndex="-1">
              <h3 className="modal-title fw-600 h3 mb-3">Select Course Upgrades & Add-ons</h3>

              <UpsellGroups item={item} prefix="modal-" />
            </div>
            <div className="modal-footer p-3 p-md-4 pt-1 pt-md-1 border-0">
              <button
                type="button"
                className="btn btn-link px-3 fw-600 text-decoration-none"
                data-bs-dismiss="modal"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary px-5 fw-700"
                data-bs-dismiss="modal"
                onClick={handleModalCheckout}
              >
                Checkout Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
