// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'ny5HourPreLicensingSpanish', // this used as the field name as well as the unique identifier
    productIds: [
      '99fd1642-93de-4162-a48d-9eba4e808b74', // Curso De 5 Horas Para La Pre-Licencia De Nueva York (Curso en español)
    ],
    errorMsg: `El estudiante debe estar de acuerdo con el contrato de inscripción al <strong>Curso De 5 Horas Para La Pre-Licencia De Nueva York</strong> escribiendo "<strong>I agree</strong>" en el cuadro resaltado.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'iAgree', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      El Estudiante acepta los métodos de validación utilizados en el curso en línea de Pre-Licencia de Nueva York y
      descritos en el{' '}
      <a
        href="https://cdn.certus.com/global/pdf/ny-5-hour-enrollment-contract-es.pdf"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        Curso De 5 Horas Para La Pre-Licencia De Nueva York
      </a>{' '}
      al escribir &quot;I agree&quot; en el campo a continuación.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
