import { useState, useEffect, useContext, useRef } from 'react';
import { Modal } from 'bootstrap';
import { CartContext } from '../../store/CartContext';

export default function FeeDescriptionModal() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const feeDescriptionModalEl = useRef();

  const [feeDescriptionModal, setFeeDescriptionModal] = useState(null);
  const [fee, setFee] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    setFeeDescriptionModal(new Modal(feeDescriptionModalEl.current));

    feeDescriptionModalEl.current.addEventListener('hide.bs.modal', () => {
      // trigger to hide the toast notification
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          showFeeDescription: null,
        },
      });
    });
  }, [cartDispatch]);

  useEffect(() => {
    if (!cartState.userExperience.showFeeDescription && feeDescriptionModal) {
      feeDescriptionModal.hide();
    }
    if (cartState.userExperience.showFeeDescription && feeDescriptionModal) {
      feeDescriptionModal.show();
    }
  }, [cartState.userExperience.showFeeDescription, feeDescriptionModal]);

  useEffect(() => {
    if (cartState.userExperience.showFeeDescription) {
      setFee({
        name: cartState.userExperience.showFeeDescription.name,
        description: cartState.userExperience.showFeeDescription.description,
      });
    }
  }, [cartState.cartProducts, cartState.userExperience.showFeeDescription]);

  return (
    <div
      className="modal fade text-start lh-16"
      ref={feeDescriptionModalEl}
      id="fee-description-modal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="fee-description-modal modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-3 p-md-4">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3 className="modal-title fw-600 h5 mb-2">{fee.name}</h3>
            {fee.description ? (
              <p className="line-break fw-400 mb-0">{fee.description}</p>
            ) : (
              <p className="line-break fw-400 mb-0">
                State, local or other regulatory agencies often charge fees that are not included in your course price. This
                processing or reporting fee is required for each learner.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
