import { useState, useEffect, useContext, useRef } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { formatCurrency as currency } from '../../utils/formatCurrency';
import { getDisplayPrice } from '../../utils/amountHelper';
import { ecomRemoveProduct } from '../../utils/ecomHelper';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';
import showFeesInCart from '../../utils/showFeesInCart';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
OrderSummaryItem.propTypes = {
  item: PropTypes.object.isRequired,
  isSingle: PropTypes.bool.isRequired,
  showQtyControls: PropTypes.bool.isRequired,
  showQtyInput: PropTypes.bool.isRequired,
};

export default function OrderSummaryItem({ item, isSingle, showQtyControls, showQtyInput }) {
  const cartId = item.cartId;
  // console.log('cartItem', item);

  const showFees = showFeesInCart(item.productId) && !item.hiddenFee && item.unitThirdPartyFee > 0;

  const [cartState, cartDispatch] = useContext(CartContext);

  const [itemState, setItemState] = useState({
    isLoading: false,
    isRemovingItem: false,
    isUpdatingItem: false,
    ...item,
  });

  useEffect(() => {
    setItemState((state) => ({
      ...state,
      ...item,
    }));
  }, [item]);

  const qtyDebounce = useRef();

  const itemDispayPrice = getDisplayPrice(item) * item.quantity;

  const handleQtyChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    let quantity = itemState.quantity;

    if (name === 'quantity' && Number(theValue) > 0) {
      quantity = Number(theValue);
    }
    if (name === 'decrement' && itemState.quantity > 1) {
      quantity = quantity - 1;
    }
    if (name === 'increment') {
      quantity = quantity + 1;
    }

    if (itemState.quantity !== quantity) {
      setItemState((state) => ({
        ...state,
        quantity,
        isLoading: true,
      }));

      cartDispatch({
        type: 'UPDATE',
        payload: {
          isLoading: true,
        },
      });

      // trigger to hide the toast notification
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          showNotification: false,
        },
      });

      // simple debouncing to prevent unecessary API calls when a user continues to change quantity
      clearTimeout(qtyDebounce.current);
      qtyDebounce.current = setTimeout(() => {
        updateQty(quantity);
      }, 300);
    }
  };

  const updateQty = (qty) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        isUpdatingCart: true,
      },
    });

    setItemState((state) => ({
      ...state,
      isUpdatingItem: true,
    }));

    const product = {
      cartId: cartId,
      productId: item.productId,
      cartProductId: item.cart_ProductId,
      quantity: qty,
      isSelected: item.isSelected,
    };
    API.updateProduct(product)
      .then(async (res) => {
        // update cart state globally
        let cart = res.data;

        setItemState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingItem: false,
        }));

        // updating local cart with data from API
        cartDispatch({
          type: 'UPDATE',
          payload: {
            ...cart,
            isLoading: false,
            isUpdatingCart: false,
          },
        });

        // trigger to show the toast notification
        cartDispatch({
          type: 'EXPERIENCE',
          payload: {
            showNotification: true,
          },
        });
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        // reload the page if the API call fails, ideally show an error message
        window.location.reload();
      });
  };

  const handleRemoveFromCart = () => {
    setItemState((state) => ({
      ...state,
      isRemovingItem: true,
    }));

    // console.log('cartProductId', cartProductId);
    cartDispatch({
      type: 'UPDATE',
      payload: {
        isLoading: true,
        isUpdatingCart: true,
      },
    });

    // actual API call that updates the cart
    API.removeProduct(cartId, item.cart_ProductId)
      .then(async (res) => {
        // update cart state globally
        let cart = res.data;

        setItemState((state) => ({
          ...state,
          isRemovingItem: false,
        }));

        // updating local cart with data from API
        cartDispatch({
          type: 'UPDATE',
          payload: {
            ...cart,
            isLoading: false,
            isUpdatingCart: false,
          },
        });

        // trigger to show the toast notification
        cartDispatch({
          type: 'EXPERIENCE',
          payload: {
            showNotification: true,
          },
        });

        ecomRemoveProduct(item);
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        // reload the page if the API call fails, ideally show an error message
        window.location.reload();
      });
  };

  const showBulkRates = () => {
    // trigger to show the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showBulkRates: {
          name: item.name,
          bulkRates: item.cartProductBulkTiers,
        },
      },
    });
  };

  const toggleFeeDescription = () => {
    // show fee description
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showFeeDescription: {
          name: item.thirdPartyFeeName,
          description: item.feeDescription,
        },
      },
    });
  };

  const hasAccessoryUpsells = item.upSellProducts.length
    ? item.upSellProducts.filter((upsell) => upsell.upsellSectionId !== 5).length > 0
    : false;

  const replaceUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 1 && upsell.isSelected)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const accessoryUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 2 && upsell.isSelected)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const digitalUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 3 && upsell.isSelected)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const shippingUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 4 && upsell.isSelected)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const relatedUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 5 && upsell.isSelected)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const selectedUpsells = [...replaceUpsells, ...accessoryUpsells, ...digitalUpsells, ...shippingUpsells, ...relatedUpsells];

  const itemUpsells = selectedUpsells.map((upsell) => {
    const itemDispayPrice = getDisplayPrice(upsell, true) * item.quantity;

    return (
      <li className="list-group-item border-0 lh-14 p-0 mb-1" data-product-id={upsell.productId} key={upsell.productId}>
        <div className="item-name">{upsell.name}</div>
        <div className="item-amount text-end">
          {upsell.upsellType === 'Replace' ? (
            itemState.isLoading ? (
              <span className="animated-placeholder rounded-2 w-70 height-sm" style={{ minWidth: '60px' }}></span>
            ) : (
              'Included'
            )
          ) : itemState.isLoading ? (
            <span className="animated-placeholder rounded-2 w-70 height-sm" style={{ minWidth: '60px' }}></span>
          ) : (
            currency(itemDispayPrice)
          )}
        </div>
      </li>
    );
  });

  return (
    <li
      className={`list-group-item d-block px-0 ${itemState.isRemovingItem ? 'is-removing-item' : ''}`}
      data-product-id={item.productId}
    >
      {showQtyControls && (
        <div
          className={`cart-item-action d-flex align-items-center justify-content-between ${
            isSingle && hasAccessoryUpsells && showQtyInput ? 'py-2' : ''
          }`}
        >
          {!isSingle || (isSingle && !hasAccessoryUpsells) || !showQtyInput ? (
            <div className="me-4">Qty: {itemState.quantity}</div>
          ) : (
            <>
              <div className="cart-item-qty d-flex align-items-center">
                {item.upsellType === 'Retake' ? (
                  <div className="me-4">Qty: {itemState.quantity}</div>
                ) : (
                  <div
                    className={`input-group me-3 ${
                      cartState.isUpdatingCart || itemState.isUpdatingItem
                        ? 'is-loading'
                        : // : itemState.isUpdatingItem
                          // ? 'is-updating-item'
                          ''
                    }`}
                  >
                    <button
                      className="qty-btn btn btn-sm btn-light"
                      onClick={handleQtyChange}
                      type="button"
                      name="decrement"
                      disabled={itemState.quantity === 1}
                      aria-label="Decrement"
                    >
                      &#8722;
                    </button>
                    <label className="visually-hidden" htmlFor={`summary-qty-${item.cart_ProductId}`}>
                      Quantity
                    </label>
                    <input
                      id={`summary-qty-${item.cart_ProductId}`}
                      className="qty-input form-control"
                      name="quantity"
                      type="number"
                      value={itemState.quantity}
                      onChange={handleQtyChange}
                      min="1"
                      aria-label="Item Quantity"
                    />
                    <button
                      className="qty-btn btn btn-sm btn-light"
                      onClick={handleQtyChange}
                      type="button"
                      name="increment"
                      aria-label="Increment"
                    >
                      &#43;
                    </button>
                  </div>
                )}

                {item.cartProductBulkTiers && item.cartProductBulkTiers.length > 0 && (
                  <div className="bulk-discount-toggle d-inline-block text-muted lh-1">
                    <button className="btn-toggle fw-400 small text-reset lh-1 p-0" type="button" onClick={showBulkRates}>
                      Bulk Rates<span className="info-icon align-middle">?</span>
                    </button>
                  </div>
                )}
              </div>

              <div className={`text-end text-muted ${item.upsellType !== 'Retake' ? 'my-1' : ''}`}>
                <button
                  className="delete-btn btn text-muted"
                  onClick={handleRemoveFromCart}
                  type="button"
                  name="delete"
                  aria-label="Delete Item"
                  disabled={cartState.isLoading || itemState.isRemovingItem}
                >
                  {itemState.isRemovingItem ? (
                    <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
                  ) : (
                    <DeleteIcon aria-hidden="true" />
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      )}

      <div className="cart-item d-flex fw-600 lh-14 py-1">
        <div className="item-name">{item.name}</div>
        <div className="item-amount text-end">
          {itemState.isLoading ? (
            <span className="animated-placeholder rounded-2 w-70 height-sm" style={{ minWidth: '60px' }}></span>
          ) : (
            currency(itemDispayPrice)
          )}
        </div>
      </div>
      {(itemUpsells.length > 0 || showFees) && (
        <ul className="cart-item-upsell list-group-flush bg-transparent ps-2 m-0 mt-1">
          {showFees && (
            <li className="list-group-item border-0 lh-14 p-0 mb-1">
              <div className="item-name">
                {item.thirdPartyFeeName}{' '}
                <button
                  className="btn-toggle fee-description-toggle fw-400 small text-reset lh-1 p-0 ms-1"
                  type="button"
                  onClick={toggleFeeDescription}
                >
                  <span className="info-icon align-middle ms-0">?</span>
                </button>
              </div>
              <div className="item-amount text-end">
                {itemState.isLoading ? (
                  <span className="animated-placeholder rounded-2 w-70 height-sm" style={{ minWidth: '60px' }}></span>
                ) : (
                  currency(item.unitThirdPartyFee * item.quantity)
                )}
              </div>
            </li>
          )}

          {itemUpsells}
        </ul>
      )}
    </li>
  );
}
